import React from "react";

// reactstrap components
import { Container, Row, Col } from "reactstrap";

// core components

function SectionAboutMe() {
  return (
    <>
      <div className="section section-components section-dark"  id="aboutme">
        <Row className="align-items-center">
          <Col lg="6" md="12">
            <div className="image-container">
            <img
                alt="..."
                className="components-anime"
                src={require("assets/img/anime.png")}
                
              />
            </div>
          </Col>
          <Col className="ml-auto mr-auto" lg="4" md="10">
            <Container className="basic-container about-me-container">
              <h3 className="title about-me">About Me &nbsp;               
              <i className="nc-icon nc-bulb-63"></i>
              </h3>
              <h5 className="description about me">
              I'm a passionate <span style={{ color: "#eb5e28"}}>Software Engineer</span> with expertise in the
              <span style={{ color: "#eb5e28"}}> .NET ecosystem</span>, building scalable and efficient applications.
              <br /><br />
              Over the years, I've worked on <span style={{ color: "#eb5e28"}}> innovative projects</span>, creating
              seamless user interfaces and interactive web applications that
              provide real-world solutions.
              <br /><br />
              I thrive in fast-paced environments, solving challenges with
              creativity and determination. My commitment to continuous
              learning ensures I stay ahead in the ever-evolving tech
              landscape.
              </h5>
            </Container>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default SectionAboutMe;
