import React from "react";
import { Container, Button } from "reactstrap";


function PortfolioHeader() {
  return (
    <>
      <div className="wrapper" id="topheader">
        <div
          className="page-header section-dark"
          style={{
            backgroundImage:
              "url(" + require("assets/img/sections/headerimage.jpg") + ")",
          }}
        >
          <div className="content-center">
            <Container>
              <div className="title-brand">
              <h1 className="portfolio-title">Joshua Chan</h1>
              </div>
              <h2 className="portfolio-subtitle text-center">
                <span><strong>Software Engineer</strong></span>
                <br/>
              
              </h2>
              <h5 className="text-center">  
              <span>C# ASP .NET (Core) (MVC) (ENTITY FRAMEWORK) | HTML/CSS/JAVASCRIPT (JQuery) (REACT CORE UI)</span>
                <br/>
              <span>MS SQL | ORACLE DB | MICROSERVICES | MS AZURE CLOUD | Azure DevOps (CI/CD)</span></h5>
              <a href="/Joshua_Chan_Resume.pdf" target="_blank" rel="noopener noreferrer" download>
                  <Button color="default" size="lg">
                      <i className="nc-icon nc-cloud-download-93" /> &nbsp;
                      Download CV
                  </Button>
              </a>
            </Container>
          </div>
          <h6 className="category category-absolute">
            Best view on desktop | Portfolio fully coded in React JS | Joshua Chan Jun Wei | Singapore{" "} 
           
              <img
                alt="..."
                className="joshuaheaderfootter"
                src={require("assets/img/singaporeflag.png")}
                style={{
                  width: '30px',
                  height: 'auto'
              }}
              />
            
          </h6>

        </div>
      </div>
    </>
  );
}

export default PortfolioHeader;
